import customerActivityService from "@services/customerActivity/customerActivityService";
import { action, observable } from "mobx";
import type { PagedResultDto } from "@services/dto/pagedResultDto";

class CustomerActivityStore {
  @observable isLoading!: boolean;
  @observable activityPageViews!: any;
  @observable activityHistories!: PagedResultDto<any>;
  @observable activitySummary!: PagedResultDto<any>;

  constructor() {
  }

  @action
  async createActivity(body: any) {
    this.isLoading = true;
    await customerActivityService.createActivity(body).finally(() => (this.isLoading = false));
  }

  @action
  async create(body: any) {
    this.isLoading = true;
    await customerActivityService.create(body).finally(() => (this.isLoading = false));
  }

  @action
  async createStockVisit(body: any) {
    this.isLoading = true;
    await customerActivityService.createStockVisit(body).finally(() => (this.isLoading = false));
  }

  @action
  async getActivityPageViews(params: any) {
    this.isLoading = true;
    const result = await customerActivityService.getActivityPageViews(params).finally(() => (this.isLoading = false));
    this.activityPageViews = result;
    this.activityHistories = result?.histories;
  }

  @action
  async getActivityHistories(params: any) {
    this.isLoading = true;
    const result = await customerActivityService.getActivityHistories(params).finally(() => (this.isLoading = false));
    this.activityHistories = result;
  }

  @action
  async getActivitySummary(params: any) {
    this.isLoading = true;
    const result = await customerActivityService.getActivitySummary(params).finally(() => (this.isLoading = false));
    this.activitySummary = result;
  }
}

export default CustomerActivityStore;
