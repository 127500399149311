import http from "../httpService";

class AdminEOIService {
  public async apiGetPaymentInfo(projectId: number, search: string = ""): Promise<any> {
    const res = await http.get(`api/services/app/PaymentProjectSettingService/GetPaymentProjectSettingInfo?projectId=${projectId}&search=${search}`);
    return res || {};
  }
  public async apiCreateUpdatePayment(params: any): Promise<any> {
    const res = await http.post(`api/services/app/PaymentProjectSettingService/CreateOrUpdatePaymentProjectSetting`, params);
    return res || {};
  }
  public async apiDeletedPayment(id: string): Promise<any> {
    const res = await http.delete(`api/services/app/PaymentProjectSettingService/DeletePaymentProjectSettingById?id=${id}`);
    return res || {};
  }
}

export default new AdminEOIService();
