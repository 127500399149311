import { action, observable } from "mobx";
import documentService from "@services/document/documentService";

class DocumentStore {
  @observable results!: any;

  @action
  async getDocuments(uniqueId: string) {
    const result = await documentService.getDocuments(uniqueId);

    if (result?.length > 0) {
      this.results = result;
    } else {
      this.results = [];
    }
  }

}

export default DocumentStore;
