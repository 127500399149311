import type { PagedResultDto } from "../dto/pagedResultDto";
import http from "../httpService";
import { L, LNotification } from "../../lib/abpUtility";
import { notifyError, notifySuccess } from "../../lib/helper";
import { CategoryDetail } from "@models/project/categoryModel";

class UnitService {
  public async create(body: any) {
    const result = await http.post(
      "api/services/app/Unit/CreateOrUpdate",
      body
    );
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async update(body: any) {
    const result = await http.post(
      `api/services/app/Unit/CreateOrUpdate`,
      body
    );
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async publicOrUnpublic(id: number, isActive) {
    const result = await http.post(
      "api/services/app/Unit/Active",
      { id },
      { params: { isActive } }
    );
    return result.data;
  }

  public async get(id: number): Promise<any> {
    if (!id) {
      notifyError(L("Error"), L("EntityNotFound"));
    }

    const result = await http.get(`api/services/app/Unit/Get`, {
      params: { id },
    });

    return CategoryDetail.assign(result.data.result);
  }

  public async getList(projectId: number): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Unit/GetAll", {
      params: {
        projectId: projectId,
        sorting: "order asc",
      },
    });
    const { result } = res.data;

    return result;
  }

  public async getListCateWithFilter(params: any): Promise<any> {
    const res = await http.get("api/services/app/Unit/GetAll", {
      params,
    });
    const { result } = res.data;

    return result;
  }

  public async orderList(orderList: number[]) {
    const result = await http.post(
      `api/services/app/Unit/OrderList`,
      orderList
    );
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }
}

export default new UnitService();
