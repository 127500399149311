import { RowData } from "@models/DataTable";

export interface IRowAgent {}

export class RowAgentModel extends RowData implements IRowAgent {
  constructor() {
    super();
  }

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new RowAgentModel(), obj);

    return newObj;
  }

  public static assigns<T>(objs) {
    const results: any[] = [];
    (objs || []).forEach((item) => results.push(this.assign(item)));
    return results;
  }
}
