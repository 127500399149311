import { action, observable } from "mobx";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import projectService from "@services/projects/projectService";

class ProjectStore {
  @observable isLoading!: boolean;
  @observable projectData!: PagedResultDto<any>;
  @observable editProject!: any;
  @observable customerProjectDetail!: any;
  @observable publicProjectDetail!: any;
  @observable currentProjectDetail!: any;
  @observable customerProjectCategoryDetail!: any;

  constructor() {
    this.projectData = { items: [], totalCount: 0 };
  }

  @action
  async createProject() {
    this.editProject = {
      projectCode: "",
      projectName: "",
      isActive: true,
      projectAddress: "",
    };
  }

  @action
  async CreateOrUpdate(updateInput: any) {
    this.isLoading = true;
    const body = {
      ...updateInput,
      projectAddress: updateInput?.projectAddress
        ? [
            {
              address: updateInput?.projectAddress ?? "",
              isActive: true,
            },
          ]
        : undefined,
    };
    this.editProject = await projectService
      .CreateOrUpdate(body)
      .finally(() => (this.isLoading = false));
  }

  // @action
  // async delete(id: number) {
  //   await projectService.delete(id);
  //   this.projectData.items = this.projectData.items.filter((x) => x.id !== id);
  // }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await projectService.activateOrDeactivate(id, isActive);
  }

  @action
  async get(id: number) {
    this.isLoading = true;
    this.editProject = await projectService
      .get(id)
      .finally(() => (this.isLoading = false));
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    this.projectData = await projectService
      .getList(params)
      .finally(() => (this.isLoading = false));
  }

  @action
  async getCustomerProjectDetail(params: any) {
    this.isLoading = true;
    this.customerProjectDetail = await projectService
      .getCustomerProjectDetail(params)
      .finally(() => (this.isLoading = false));
  }

  @action
  async getPublicProjectDetail(params: any) {
    this.isLoading = true;
    this.publicProjectDetail = await projectService
      .getPublicProjectDetail(params)
      .finally(() => (this.isLoading = false));
  }

  @action
  async getCurrentProjectDetail(params: any) {
    this.isLoading = true;
    this.currentProjectDetail = await projectService
      .getCurrentProjectDetail(params)
      .finally(() => (this.isLoading = false));
  }

  @action
  async getCustomerProjectCategoryDetail(params: any) {
    this.isLoading = true;
    this.customerProjectCategoryDetail = await projectService
      .getCustomerProjectCategoryDetail(params)
      .finally(() => (this.isLoading = false));
  }
}

export default ProjectStore;
