import http from "../httpService";
import { L } from "../../lib/abpUtility";
import { notifyError } from "../../lib/helper";
import { FileModel } from "@models/File";

class DocumentService {
  
  public async getDocuments(uniqueId: string): Promise<FileModel[]> {
    if (!uniqueId) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const res = await http.get("api/services/app/Documents/GetDocuments", {
      params: { uniqueId },
    });
    const result = res.data.result;
    return FileModel.assigns(result || []);
  }

}

export default new DocumentService();
