import adminEOI from "@services/adminEOI/adminEOI";
import { action, observable } from "mobx";

class AdminEOI {
  @observable isLoading!: boolean;
  @observable listPaymentData!: any[];
  @observable masterDataPaymnet!: any[];

  @action
  async callGetPaymentList(projectId: number, search: string = '') {
    this.isLoading = true;
    try {
      const { data } = await adminEOI.apiGetPaymentInfo(projectId, search)
      if (data.success && data.result) {
        this.masterDataPaymnet = data.result;
        this.listPaymentData = data.result.items;
      }
    } catch (e) {
      console.log("== e-get-payment-list ==>", e)
    }
    this.isLoading = false;
  }
  @action
  async callCreateUpdatePayment(params: any) {
    this.isLoading = true;
    try {
      const { data } = await adminEOI.apiCreateUpdatePayment(params);
      this.isLoading = false;
      return data;
    } catch (e) {
      console.log("== e-update-payment-list ==>", e)
    }
    this.isLoading = false;
  }
  @action
  async callDeletePayment(id: string) {
    this.isLoading = true;
    try {
      const { data } = await adminEOI.apiDeletedPayment(id);
      this.isLoading = false;
      return data;
    } catch (e) {
      console.log('== e-delete ===>', e);
    }
    this.isLoading = false;
  }

  @action
  setLoadinPage(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}

export default AdminEOI;
