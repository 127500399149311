import { notifyError, notifySuccess } from "@lib/helper";
import { L, LNotification } from "@lib/abpUtility";
import http from "@services/httpService";
import { PagedResultDto } from "@services/dto/pagedResultDto";

class CustomerService {
  public async get(id: number): Promise<any> {
    if (!id) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }
    const res = await http.get("api/services/app/Customers/Get", {
      params: { id },
    });

    return res.data.result;
  }

  public async update(body: any) {
    const result = await http.put("api/services/app/Customers/Update", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async insertOrUpdateCustomerInterestStock(body: any) {
    const result = await http.post("api/services/app/Customers/InsertOrUpdateCustomerInterestStock", body);
    return result.data.result;
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Customers/GetAll", { params });
    const result = res.data.result;

    return result;
  }

  public async getCustomersWithFilterAndPaginationByProject(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Customers/GetCustomersWithFilterAndPaginationByProject", { params });
    const result = res.data.result;

    return result;
  }

  public async createPreference(body: any) {
    const result = await http.post("api/services/app/CustomerCommunicationProperties/Create", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async updatePreference(body: any) {
    const result = await http.put("api/services/app/CustomerCommunicationProperties/Update", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async getPreference(customerId: any): Promise<PagedResultDto<any>> {
    if (!customerId) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }
    const res = await http.get("api/services/app/CustomerCommunicationProperties/GetByCustomerId", {
      params: { customerId },
    });
    return res.data.result;
  }

  public async changeStatusCommunicationPreference(param: any) {
    const result = await http.post("api/services/app/CustomerCommunicationProperties/ChangeStatus", param);
    return result.data.result;
  }
}

export default new CustomerService();
