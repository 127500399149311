import { observable } from 'mobx'

class LoginModel {
  emailAddress!: string
  tenancyName!: string
  userNameOrEmailAddress!: string
  password!: string
  projectId!: number
  agentId!: number
  @observable rememberMe!: boolean
  @observable showModal!: boolean

  toggleRememberMe = () => {
    this.rememberMe = !this.rememberMe
  }

  toggleShowModal = () => {
    this.showModal = !this.showModal
  }
}

export default LoginModel
