import { action, observable } from "mobx";
import categoryService from "@services/projects/categoryService";
import AppConsts from "@lib/appconst";
import { CategoryDetail } from "@models/project/categoryModel";
const { unitDetailType } = AppConsts;

class CategoryStore {
  @observable isLoading!: boolean;
  @observable editCategory!: CategoryDetail;
  @observable categoryTypes: any = [];

  @action
  async initCategory() {
    this.editCategory = {
      unitName: "",
      description: "",
      floorplan: {
        unitUrl: "",
        unitDetailType: unitDetailType.Floorplan,
        isActive: false,
      },
      renders: {
        unitUrl: "",
        unitDetailType: unitDetailType.Renders,
        isActive: false,
      },
      virtualWalk: {
        unitUrl: "",
        unitDetailType: unitDetailType.VirtualWalkthorough,
        isActive: false,
      },
      brochure: {
        unitUrl: "",
        unitDetailType: unitDetailType.PdfBrochure,
        isActive: false,
      },
      video: {
        unitUrl: "",
        unitDetailType: unitDetailType.Video,
        isActive: false,
      },
    };
  }

  @action
  async create(body: any) {
    await categoryService.create(body);
  }

  @action
  async update(body: any) {
    await categoryService.update(body);
  }

  @action
  async publicOrUnpublic(id: number, isActive) {
    await categoryService.publicOrUnpublic(id, isActive);
  }

  @action
  async get(id: number) {
    const result = await categoryService.get(id);
    this.editCategory = result;
  }

  @action
  async getList(projectId: number) {
    this.isLoading = true;
    const result = await categoryService
      .getList(projectId)
      .finally(() => (this.isLoading = false));
    this.categoryTypes = result;
  }
  @action
  async orderList(listOrder: number[]) {
    await categoryService.orderList(listOrder);
  }

  @action
  setLoadingPage(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}

export default CategoryStore;
