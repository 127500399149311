import {
  AuthenticationCustomerModel,
  AuthenticationModel,
  SwitchProjectModel
} from './dto/authenticationModel'
import {AuthenticationResultModel} from './dto/authenticationResultModel'
import http from '../httpService'

class TokenAuthService {
  public async authenticateWithCustomer(
    authenticationInput: AuthenticationCustomerModel
  ): Promise<AuthenticationResultModel> {
    const result = await http.post(
      'api/TokenAuth/TokenAuthen',
      authenticationInput
    )
    return result.data.result
  }

  public async authenticate(
    authenticationInput: AuthenticationModel
  ): Promise<AuthenticationResultModel> {
    const result = await http.post(
      'api/TokenAuth/Authenticate',
      authenticationInput
    )
    // Test call auth in local
    // const httpTest = axios.create({
    //   baseURL: 'http://localhost:21021/',
    //   timeout: 30000,
    //   paramsSerializer: function (params) {
    //     return qs.stringify(params, {
    //       encode: params.keyword?.includes('#')
    //     })
    //   }
    // })

    // const result = await httpTest.post(
    //   'api/TokenAuth/Authenticate',
    //   authenticationInput
    // )

    return result.data.result
  }

  public async authenticateWithAdmin(
    authenticationInput: AuthenticationModel
  ): Promise<AuthenticationResultModel> {
    const result = await http.post(
      'api/TokenAuth/Authenticate',
      authenticationInput
    )
    return result.data.result
  }

  public async switchProject(
    targetProjectId
  ): Promise<AuthenticationResultModel> {
    const result = await http.post(
      'api/TokenAuth/SwitchProjectAuthenticate',
      null,
      {params: {targetProjectId}}
    )
    return SwitchProjectModel.assign(result.data.result)
  }

  public async getLoginMethod(): Promise<any> {
    const result = await http.get('api/TokenAuth/GetAuthenticationProviders')
    return result.data.result
  }

  public async socialAuth(body: any): Promise<any> {
    const result = await http.post('api/TokenAuth/ExternalAuthenticateV1', body)
    return result.data.result
  }

  public async checkPhoneNumber(phoneNumber) {
    const result = await http.post(
      'api/services/app/Account/IsPhoneNumberAvailable',
      {phoneNumber}
    )
    return result.data.result
  }
  public async checkSocial(body) {
    const result = await http.post(
      'api/services/app/Account/IsSocialLoginAvailable',
      body
    )
    return result.data.result
  }

  public async SMSAuth(body: any): Promise<any> {
    const result = await http.post('api/TokenAuth/PhoneLoginAuthenticate', body)
    return result.data.result
  }

  public async registerBySMS(body) {
    const result = await http.post(
      'api/TokenAuth/RegisterViaPhoneNumberAuthenticate',
      body
    )
    return result.data.result
  }

  public async registerAccount(body) {
    const result = await http.post('api/TokenAuth/Register', body)
    return result.data.result
  }

  public async registerAgentAccount(body) {
    const result = await http.post('api/TokenAuth/RegisterAgent', body)
    return result.data.result
  }

  public async sendOtpVerifyLogin(body) {
    const result = await http.post('api/TokenAuth/SendOtpVerifyLogin', body)
    return result.data.result
  }

  public async verifyOtpLogin(body) {
    const result = await http.post('api/TokenAuth/VerifyOtpLogin', body)
    return result.data.result
  }
}

export default new TokenAuthService()
