class ForgotPasswordModel {
  emailAddress!: string
}

export class ResetPasswordModel {
  emailAddress!: string
  resetCode!: string
  password!: string
  projectId!: string
  agentId!: string
}

export default ForgotPasswordModel
