import http from "@services/httpService";

class CustomerActivityService {

  public async createActivity(body: any) {
    const result = await http.post("api/services/app/CustomerActivity/Create", body);
    return result.data.result;
  }

  public async create(body: any) {
    const result = await http.post("api/services/app/CustomerActivity/CreateProjectCategoryVisit", body);
    return result.data.result;
  }

  public async createStockVisit(body: any) {
    const result = await http.post("api/services/app/CustomerActivity/CreateProjectStockVisit", body);
    return result.data.result;
  }

  public async getActivityPageViews(params: any): Promise<any>{
    const res = await http.get("api/services/app/CustomerActivity/GetUserActivityPageViews", {
      params: params,
    });
    const { result } = res.data;
    return result;
  }

  public async getActivityHistories(params: any): Promise<any>{
    const res = await http.get("api/services/app/CustomerActivity/GetUserActivityHistories", {
      params: params,
    });
    const { result } = res.data;
    return result;
  }

  public async getActivitySummary(params: any): Promise<any>{
    const res = await http.get("api/services/app/CustomerActivity/GetUserActivitySummary", {
      params: params,
    });
    const { result } = res.data;
    return result;
  }
}

export default new CustomerActivityService();
