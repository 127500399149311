import { buildFileUrlWithEncToken } from "@lib/helper";
import { defaultAvatar } from "@lib/appconst";
import { RowData } from "@models/DataTable";

export class ProjectDetail {
  id?: number;
  uniqueId?: string;
  investorName?: string;
  hotline?: string;
  description?: string;
  address?: string;
  isActive?: boolean;
  creationTime?: Date;
  creatorUserId?: number;
  logoUrl?: string;
  code?: string;
  name?: string;

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new ProjectDetail(), obj);
    // Hack to cache image request
    newObj.logoUrl = obj.file?.fileUrl ? obj.file?.fileUrl + "&abc.png" : null;
    return newObj;
  }
}

export class ProjectOptionModel {
  id?: number;
  name?: string;
  normalizedName?: string;
  value?: number;
  label: string;
  code: string;
  logoUrl: string;
  constructor() {
    this.label = "";
    this.code = "";
    this.logoUrl = "";
  }

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new ProjectOptionModel(), obj);
    newObj.show = true;
    newObj.value = obj.id;
    newObj.label = obj.name;
    newObj.normalizedName = (newObj.name || "").toLowerCase();
    newObj.logoUrl =
      obj.file?.fileUrl && obj.file?.fileUrl.length
        ? buildFileUrlWithEncToken(obj.file?.fileUrl)
        : defaultAvatar;
    return newObj;
  }

  public static assigns<T>(objs) {
    const results: ProjectOptionModel[] = [];
    objs.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export interface IRowProject {
  id: string;
  projectName: string;
  description: string;
}

export class RowProjectModel {
  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new RowProjectModel(), obj);
    newObj.projectName = obj.projectName;

    return newObj;
  }

  public static assigns<T>(objs) {
    const results: any[] = [];
    objs.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export class ProjectDetailModel extends RowData {
  constructor() {
    super();
  }

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new RowProjectModel(), obj);

    newObj.projectAddress =
      newObj.projectAddress?.length > 0 ? newObj.projectAddress[0].address : "";

    return newObj;
  }

  public static assigns<T>(objs) {
    const results: any[] = [];
    objs.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}
