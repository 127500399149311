import LoadableComponent from '../../Loadable'

import {
  ExceptionOutlined,
  LogoutOutlined,
  RadarChartOutlined,
  UserOutlined,
  AppstoreOutlined,
  FolderOpenOutlined
} from '@ant-design/icons'
import { appPermissions } from '@lib/appconst'

export const layouts: any = {
  userLayout: 'userLayout',
  portalLayout: 'appLayout',
  publicLayout: 'publicLayout',
  customerLayout: 'customerLayout',
  agentLayout: 'agentLayout',
  sharedMeetingLayout: 'sharedMeetingLayout'
}

export const layoutRouter: any = {
  userLayout: LoadableComponent(() => import('../UserLayout')),
  appLayout: LoadableComponent(() => import('../AppLayout')),
  publicLayout: LoadableComponent(() => import('../PublicLayout')),
  customerLayout: LoadableComponent(() => import('../CustomerLayout')),
  agentLayout: LoadableComponent(() => import('../AgentLayout')),
  sharedMeetingLayout: LoadableComponent(() => import('../SharedMeetingLayout'))
}

export const publicLayout: any = {
  termAndCondition: {
    path: '/public/terms-and-conditions',
    title: 'TERM_CONDITIONS',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/public/term-condition')
    )
  }
}

export const userLayout: any = {
  accountLogin: {
    path: '/account/login',
    title: 'Log In',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/accounts/Login'))
  },
  accountAdminLogin: {
    path: '/account/login-admin',
    title: 'Log In',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/LoginAdmin')
    )
  },
  forgotPassword: {
    path: '/account/forgot-password',
    title: 'Forgot Password',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/ForgotPassword')
    )
  },
  forgotAdminPassword: {
    path: '/account/forgot-password-admin',
    title: 'Forgot Password',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/ForgotPasswordAdmin')
    )
  },
  resetPassword: {
    path: '/account/reset-password',
    title: 'Reset Password',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/ResetPassword')
    )
  },

  register: {
    path: '/account/register',
    title: 'Register Account',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/Register')
    )
  },
  registerAgent: {
    path: '/account/register-agent',
    title: 'Register Agent Account',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/RegisterAgent')
    )
  },
  registerByOTP: {
    path: '/account/register-by-otp',
    title: 'Register Account By OTP',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/Register/SMSRegisterAccount')
    )
  }
}

export const customerLayout: any = {
  customers: {
    path: '/customers/welcome',
    title: 'Customer',
    layout: layouts.customerLayout,
    component: LoadableComponent(() => import('../../../scenes/customers'))
  }
}

export const portalLayouts: any = {
  // Portal
  appSetting: {
    path: '/app-setting',
    permission: '',
    title: 'App Setting',
    name: 'APP_SETTING',
    layout: layouts.portalLayout,
    icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/appSetting/AppSetting')
    )
  },
  accountLogout: {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'LOGOUT',
    layout: layouts.portalLayout,
    icon: LogoutOutlined,
    component: LoadableComponent(() => import('../../Logout'))
  },
  accountConfigMyProfile: {
    path: '/account-config/my-profile',
    permission: '',
    title: 'My Profile',
    name: 'MY_PROFILE',
    layout: layouts.portalLayout,
    // icon: LogoutOutlined,
    component: LoadableComponent(() => import('../../../scenes/accounts/'))
  },
  accountConfigChangePassword: {
    path: '/account-config/change-password',
    permission: '',
    title: 'Change Password',
    name: 'CHANGE_PASSWORD',
    layout: layouts.portalLayout,
    // icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/AccountConfig/ChangePassword')
    )
  },
  accountConfigHistory: {
    path: '/account-config/history',
    permission: '',
    title: 'History',
    name: 'HISTORY',
    layout: layouts.portalLayout,
    // icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Exception')
    )
  },
  accountConfigSavedShortcuts: {
    path: '/account-config/saved-shortcuts',
    permission: '',
    title: 'Saved Shortcuts',
    name: 'SAVED_SHORTCUTS',
    layout: layouts.portalLayout,
    // icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Exception')
    )
  },
  accountConfigChatWithSupport: {
    path: '/account-config/chat-with-support',
    permission: '',
    title: 'Chat with support',
    name: 'CHAT_WITH_SUPPORT',
    layout: layouts.portalLayout,
    // icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Exception')
    )
  },
  accountConfigSendFeedback: {
    path: '/account-config/send-feedback',
    permission: '',
    title: 'Send Feedback',
    name: 'SEND_FEEDBACK',
    layout: layouts.portalLayout,
    // icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Exception')
    )
  },
  accountConfigSetting: {
    path: '/account-config/setting',
    permission: '',
    title: 'Setting',
    name: 'SETTING',
    layout: layouts.portalLayout,
    // icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Exception')
    )
  },
  exception: {
    path: '/exception',
    permission: '',
    title: 'exception',
    name: 'EXCEPTION',
    layout: layouts.portalLayout,
    icon: ExceptionOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Exception')
    )
  },
  // dashboard: {
  //   path: "/dashboard",
  //   name: "DASHBOARD",
  //   permission: "PagesAdministration.Dashboard",
  //   title: "Dashboard",
  //   layout: layouts.portalLayout,
  //   icon: PieChartOutlined,
  //   component: LoadableComponent(
  //     () => import("../../../scenes/common/Dashboard")
  //   ),
  // },
  notification: {
    path: '/user-notification',
    name: 'USER_NOTIFICATION',
    permission: '',
    title: 'User Notification',
    layout: layouts.portalLayout,
    icon: RadarChartOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Notification')
    )
  },

  // Admin
  adminUser: {
    path: '/users',
    permission: appPermissions.adminUser.page,
    title: 'Users',
    name: 'ADMINISTRATION_USER',
    layout: layouts.portalLayout,
    icon: UserOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/Users')
    )
  },

  adminLanguages: {
    path: '/language',
    permission: appPermissions.adminLanguage.page,
    title: 'Languages',
    name: 'ADMINISTRATION_LANGUAGE',
    layout: layouts.portalLayout,
    icon: AppstoreOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/Languages')
    )
  },
  adminLanguageTexts: {
    path: '/language-text/:id',
    // permission: appPermissions.adminLanguage.changeText,
    title: 'ADMINISTRATION_LANGUAGE_TEXT',
    name: 'ADMINISTRATION_LANGUAGE_TEXT',
    layout: layouts.portalLayout,
    icon: AppstoreOutlined,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/administrator/Languages/components/languageTexts'
        )
    )
  },
  adminOrganizationUnit: {
    path: '/organization-unit',
    permission: appPermissions.adminRole.page,
    title: 'OrganizationUnit',
    name: 'ORGANIZATION_UNIT',
    layout: layouts.portalLayout,
    icon: FolderOpenOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/OrganizationUnit')
    )
  },

  projectManagement: {
    path: '/project',
    title: 'Project',
    name: 'PROJECT',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projectManagement/index')
    )
  },

  // leadAgent: {
  //   path: '/lead-agent',
  //   name: 'LEAD_AGENT',
  //   title: 'Lead Agent',
  //   layout: layouts.portalLayout,
  //   component: LoadableComponent(
  //     () => import('../../../scenes/leadAgentManagement')
  //   )
  // },

  agentManagement: {
    path: '/agent-management',
    name: 'AGENT',
    title: 'Agent',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/agentManagement')
    )
  },

  customerAgent: {
    path: '/customer-management',
    title: 'Customer',
    name: 'CUSTOMER_MANAGEMENT',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/customerManagement')
    )
  },

  settings: {
    path: '/settings',
    name: 'Settings',
    title: 'Settings',
    // permission: appPermissions.setting.page,
    layout: layouts.portalLayout,
    // icon: CopyrightOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/settingsManagement/index')
    )
  },

  map: {
    path: 'http://property-landing.sadec.co/',
    name: 'MAP',
    // permission: appPermissions.map.page,
    layout: layouts.portalLayout,
    icon: UserOutlined,
    isExternal: true
  }
}

export const agentLayout: any = {
  agent: {
    path: '/agent/welcome',
    title: 'Agent',
    name: 'Agent',
    layout: layouts.agentLayout,
    component: LoadableComponent(() => import('../../../scenes/agent'))
  },
  customerDetails: {
    path: '/agent/customer-details',
    title: 'Customer Details',
    name: 'Customer Details',
    layout: layouts.agentLayout,
    component: LoadableComponent(
      () => import('../../../scenes/agent/CustomerDetails')
    )
  },
  sharedMeeting: {
    path: '/agent/shared-meeting',
    title: 'Shared Meeting',
    name: 'Shared Meeting',
    layout: layouts.customerLayout,
    component: LoadableComponent(() => import('../../../scenes/sharedMeeting'))
  },
  stockHome: {
    path: '/agent/stock',
    title: 'Stock list',
    name: 'Stock list',
    layout: layouts.agentLayout,
    component: LoadableComponent(() => import('../../../scenes/agentStock'))
  },
  stockDetails: {
    path: '/agent/stock-details',
    title: 'Stock Details',
    name: 'Stock Details',
    layout: layouts.agentLayout,
    component: LoadableComponent(
      () => import('../../../scenes/agentStock/agentStockDetail')
    )
  }
}

export const sharedMeetingLayout: any = {
  sharedMeeting: {
    path: '/shared-meeting',
    title: 'Shared Meeting',
    name: 'Shared Meeting',
    layout: layouts.sharedMeetingLayout,
    component: LoadableComponent(() => import('../../../scenes/sharedMeeting'))
  }
}

export const routers: any = {
  ...userLayout,
  ...portalLayouts,
  ...customerLayout,
  ...agentLayout,
  ...sharedMeetingLayout
}

export const appMenuGroups: any = [
  routers.projectManagement,
  // routers.leadAgent,
  routers.agentManagement,
  // routers.customerAgent,
  routers.settings
]

export const agentMenuGroups: any = [routers.agent]

export const accountMenuGroups: any = [
  routers.accountConfigMyProfile,
  routers.accountConfigChangePassword,
  routers.accountConfigHistory,
  routers.accountConfigSavedShortcuts,
  routers.accountConfigChatWithSupport,
  routers.accountConfigSendFeedback,
  routers.accountConfigSetting
]
