export class StockTypeDetailModel {
  potentialCustomerStockUrl: string
  uniqueVisitors: number
  returningVisitors: number
  totalPageviews: number
  id: string
  lotNumber: number
  aspect: string
  floor: number
  bedrooms: number
  bathrooms: number
  carPark: string
  internalArea: number
  balconyArea: number
  totalArea: number
  floorPlanRefId: string
  floorPlan: any
  priceUnit: string
  price: number
  makeAvailable: boolean
  projectId: number
  stockTypeId: string
  stockTypeName: string
  agentIds: any
  renderId: string
  renders: any[]
  videoId: string
  video: any
  virtualWalkthroughId: string
  virtualWalkthrough: any
  pdfBrochureId: string
  pdfBrochures: any[]
  creationTime: any

  constructor() {
    this.potentialCustomerStockUrl = ''
    this.uniqueVisitors = 0
    this.returningVisitors = 0
    this.totalPageviews = 0
    this.id = ''
    this.lotNumber = 0
    this.aspect = ''
    this.floor = 0
    this.bedrooms = 0
    this.bathrooms = 0
    this.carPark = ''
    this.internalArea = 0
    this.balconyArea = 0
    this.totalArea = 0
    this.floorPlanRefId = ''
    this.floorPlan = null
    this.priceUnit = ''
    this.price = 0
    this.makeAvailable = false
    this.projectId = 0
    this.stockTypeId = ''
    this.stockTypeName = ''
    this.agentIds = null
    this.renderId = ''
    this.renders = []
    this.videoId = ''
    this.video = null
    this.virtualWalkthroughId = ''
    this.virtualWalkthrough = null
    this.pdfBrochureId = ''
    this.pdfBrochures = []
  }

  // copy property to new obj
  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new StockTypeDetailModel(), obj)
    return newObj
  }
}
