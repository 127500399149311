import type {PagedResultDto} from '../dto/pagedResultDto'
import http from '../httpService'
import {L, LNotification} from '@lib/abpUtility'
import {notifyError, notifySuccess, safeCast} from '@lib/helper'
import {RowAgentModel} from '@models/agent/RowAgent'
import {FileModel} from '@models/File'
import {StockTypeDetailModel} from '@models/stockType/StockTypeDetailModel'
import {StockListModel, StockTypeAvailable} from '@models/stock'

class StockService {
  public async create(body: any) {
    const result = await http.post(
      'api/services/app/ProjectStockList/Create',
      body
    )
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('SAVING_SUCCESSFULLY')
    )
    return result.data.result
  }

  public async update(body: any) {
    const result = await http.put(
      'api/services/app/ProjectStockList/Update',
      body
    )
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('SAVING_SUCCESSFULLY')
    )
    return result.data.result
  }

  public async get(id: string): Promise<any> {
    if (!id) {
      notifyError(L('Error'), L('EntityNotFound'))
    }

    const result = await http.get(
      'api/services/app/ProjectStockList/GetProjectStockListDetail',
      {
        params: {stockListId: id}
      }
    )

    return StockTypeDetailModel.assign(result.data.result)
  }

  public async getStockForCustomer(id: string): Promise<any> {
    if (!id) {
      notifyError(L('Error'), L('EntityNotFound'))
    }

    const result = await http.get(
      'api/services/app/ProjectStockList/GetProjectStockListDetailForCustomerInterest',
      {
        params: {stockListId: id}
      }
    )

    return StockTypeDetailModel.assign(result.data.result)
  }

  public async getV2(id: string): Promise<any> {
    if (!id) {
      notifyError(L('Error'), L('EntityNotFound'))
    }

    const res = await http.get('api/services/app/ProjectStockList/GetProjectStockListDetail', {
      params: { stockListId: id }
    })
    return res || {};
  } 

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get(
      'api/services/app/ProjectStockList/GetStockListWithFilterAndPagination',
      {
        params: params
      }
    )
    const {result} = res.data
    result.items = RowAgentModel.assigns(result.items)
    return result
  }

  public async getStockListWithFilterAndPaginationByAgentAndStockType(
    params: any
  ): Promise<PagedResultDto<any>> {
    const res = await http.get(
      'api/services/app/ProjectStockList/GetStockListWithFilterAndPaginationByAgentAndStockType',
      {
        params: params
      }
    )
    const {result} = res.data
    result.items = RowAgentModel.assigns(result.items)
    return result
  }

  public async getFile(uniqueId: string): Promise<FileModel[]> {
    if (!uniqueId) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }

    const res = await http.get('api/services/app/Documents/GetDocuments', {
      params: {uniqueId}
    })
    const result = res.data.result
    return FileModel.assigns(result || [])
  }

  public async getAgentStockList(
    projectId: string,
    filterUrl: string
  ): Promise<PagedResultDto<any>> {
    try {
      const {data: res} = await http.get(
        `api/services/app/Agent/GetAllStocks?projectId=${projectId}&${filterUrl}`
      )
      const {result} = res
      result.data.items = StockListModel.assigns(result.data.items)
      return result.data
    } catch (error) {
      notifyError(L('Error'), L('GET_LIST_STOCK_ERROR'))
      return error
    }
  }

  public async getAgentStockType(params: any): Promise<any> {
    try {
      const {data: res} = await http.get(
        'api/services/app/ProjectStockType/GetAllStockTypeAvailable',
        {
          params
        }
      )
      const {result} = res
      return safeCast<StockTypeAvailable>(result)
    } catch (error) {
      notifyError(L('Error'), L('GET_STOCK_TYPE_ERROR'))
      return error
    }
  }

  public async getStockDetail(id: string): Promise<any> {
    if (!id) {
      notifyError(L('Error'), L('EntityNotFound'))
    }
    const result = await http.get(
      `api/services/app/Agent/GetStocksById?stockId=${id}`,
      {
        params: {stockListId: id}
      }
    )

    return StockTypeDetailModel.assign(result.data.result)
  }

  public async getStockForPotentialBuyer(stockListId: string) {
    if (!stockListId) {
      notifyError(L('Error'), L('EntityNotFound'))
    }
    const result = await http.get(
      `api/services/app/ProjectStockList/GetProjectStockListDetailForPotentialBuyers`,
      {
        params: {stockListId: stockListId}
      }
    )
    return StockTypeDetailModel.assign(result.data.result)
  }

  public async getPotentialBuyersForStock(stockListId: string, filter: string) {
    if (!stockListId) {
      notifyError(L('Error'), L('EntityNotFound'))
    }

    const result = await http.get(
      `api/services/app/Customers/GetPotentialBuyersForStockWithFilterAndPagination?stockId=${stockListId}${filter}`
    )

    return result.data.result
  }
}

export default new StockService()
