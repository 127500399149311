import customerService from "@services/customer/customerService";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import { action, observable } from "mobx";

class CustomerStore {
  @observable isLoading!: boolean;
  @observable customers!: PagedResultDto<any>;
  @observable editCustomer!: any;
  @observable customerPreference!: any;

  constructor() {
    this.customers = { items: [], totalCount: 0 };
  }

  @action
  async get(id: number) {
    const result = await customerService.get(id);
    this.editCustomer = result;
  }

  @action
  async update(body: any) {
    this.isLoading = true;
    await customerService.update(body).finally(() => (this.isLoading = false));
  }

  @action
  async insertOrUpdateCustomerInterestStock(body: any) {
    this.isLoading = true;
    await customerService.insertOrUpdateCustomerInterestStock(body).finally(() => (this.isLoading = false));
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    const result = await customerService
      .getAll(params)
      .finally(() => (this.isLoading = false));
    this.customers = result;
  }

  @action
  async getCustomersWithFilterAndPaginationByProject(params: any) {
    this.isLoading = true;
    const result = await customerService
      .getCustomersWithFilterAndPaginationByProject(params)
      .finally(() => (this.isLoading = false));
    this.customers = result;
  }

  @action
  async getPreference(customerId: any) {
    this.isLoading = true;
    const result = await customerService
      .getPreference(customerId)
      .finally(() => (this.isLoading = false));
    this.customerPreference = result;
  }

  @action
  async createPreference(body: any) {
    this.isLoading = true;
    const result = await customerService
      .createPreference(body)
      .finally(() => (this.isLoading = false));
    this.customerPreference = result;
  }

  @action
  async updatePreference(body: any) {
    this.isLoading = true;
    const result = await customerService
      .updatePreference(body)
      .finally(() => (this.isLoading = false));
    this.customerPreference = result;
  } 
  
  @action
  async changeStatusCommunicationPreference(param: any) {
    this.isLoading = true;
    const result = await customerService
      .changeStatusCommunicationPreference(param)
      .finally(() => (this.isLoading = false));
    return result;
  }

}

export default CustomerStore;
