import AppConsts from "@lib/appconst";

const { unitDetailType } = AppConsts;

export class unitDetailModel {
  unitUrl?: number;
  unitDetailType?: string;
  isShow?: boolean;

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new unitDetailModel(), obj);

    return newObj;
  }
}

export class CategoryDetail {
  id?: number;
  uniqueId?: string;
  projectId?: string;
  unitName?: string;
  description?: string;
  isActive?: boolean;
  unitDetail?: any[];
  floorplan?: any;
  renders?: any;
  virtualWalk?: any;
  brochure?: any;
  video?: any;

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new CategoryDetail(), obj);
    newObj.floorplan = obj.unitDetail.find(
      (item) => parseInt(item.unitDetailType) === unitDetailType.Floorplan
    );
    newObj.renders = obj.unitDetail.find(
      (item) => parseInt(item.unitDetailType) === unitDetailType.Renders
    );

    newObj.virtualWalk = obj.unitDetail.find(
      (item) =>
        parseInt(item.unitDetailType) === unitDetailType.VirtualWalkthorough
    );

    newObj.brochure = obj.unitDetail.find(
      (item) => parseInt(item.unitDetailType) === unitDetailType.PdfBrochure
    );

    newObj.video = obj.unitDetail.find(
      (item) => parseInt(item.unitDetailType) === unitDetailType.Video
    );

    return newObj;
  }
}
