import type { PagedResultDto } from "../../services/dto/pagedResultDto";
import http from "../httpService";
import { L, LNotification } from "../../lib/abpUtility";
import { notifyError, notifySuccess } from "../../lib/helper";
import {
  RowProjectModel,
  ProjectDetailModel,
} from "@models/project/projectModel";

class ProjectService {
  public async CreateOrUpdate(body: any) {
    const result = await http.post(
      "api/services/app/Project/CreateOrUpdate",
      body
    );
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  // public async delete(id: number) {
  //   const result = await http.delete("api/services/app/Residents/Delete", {
  //     params: { id },
  //   });
  //   return result.data;
  // }

  public async activateOrDeactivate(id: number, isActive) {
    const result = await http.post(
      "api/services/app/Project/Active",
      { id },
      { params: { isActive } }
    );
    return result.data;
  }

  public async get(id: number): Promise<any> {
    if (!id) {
      notifyError(L("Error"), L("EntityNotFound"));
    }

    const result = await http.get("api/services/app/Project/Get", {
      params: { id },
    });

    return ProjectDetailModel.assign(result.data.result);
  }

  public async getList(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Project/GetAll", { params });
    const { result } = res.data;

    return RowProjectModel.assign(result);
  }

  public async getCustomerProjectDetail(params: any): Promise<any> {
    const res = await http.get("api/services/app/Project/GetDetailByUser", { params });

    return res.data.result;
  }

  public async getPublicProjectDetail(params: any): Promise<any> {
    const res = await http.get("api/services/app/Project/GetDetailAnonymous", { params });

    return res.data.result;
  }

  public async getCurrentProjectDetail(params: any): Promise<any> {
    const res = await http.get("api/services/app/Project/GetCurrentProject", { params });

    return res.data.result;
  }

  public async getCustomerProjectCategoryDetail(params: any): Promise<any> {
    const res = await http.get("api/services/app/Unit/GetDetailByProjectId", { params });

    return res.data.result;
  }
}

export default new ProjectService();
